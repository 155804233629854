import React from 'react'
import { graphql } from 'gatsby'
import { object } from 'prop-types'

import { Hub } from '@jelly/templates'

const Index = ({
	data: { riverOfLinks = { items: [] } },
	pageContext: { props, canonical, mainContent = {}, page, pagination = { pageInfo: {} }, showLatestLink = true },
}) => {
	//All data massage should 🙏 happend here
	mainContent.content = riverOfLinks.items.map(({ _source }) => {
		_source.canonical = _source.canonical.split('.com').pop()
		return _source
	})
	props.meta.canonical = canonical
	props.moduleRiverOfLinks = {
		...props.moduleRiverOfLinks,
		mainContent,
		tab: 'article|category',
		search: {
			num_pages: pagination.pageInfo.pageCount,
		},
	}
	props.page = page
	props.showLatestLink = showLatestLink

	return <Hub {...props} />
}
//test comment

Index.propTypes = { data: object, pageContext: object }

export default Index

export const QUERY = graphql`
	query hub($page: Int, $q: String, $contentQuery: ContentQuery) {
		riverOfLinks: contentPagination(
			page: $page
			perPage: 30
			q: $q
			query: $contentQuery
			sort: displayModified__desc
		) {
			items {
				_source {
					author {
						displayName
						type
					}
					canonical
					# Need to use modified_at to match search API
					modified_at: displayModified
					doc_type: docType
					meta {
						description
					}
					stream
					subtitle
					thumbnail {
						path
					}
					title
				}
			}
		}
	}
`
